import React, { Component } from 'react';
import EventField from './eventfield';
import CollapseHeader from './collapseheader';
import Button from './button';
import Add from '@material-ui/icons/Add';
import { v4 as uuid } from "uuid";


export default class EventList extends Component {

    constructor(props) {
        super(props)

        this.addEvent = this.addEvent.bind(this)
        this.eventChanged = this.eventChanged.bind(this)

    }

    render() {
        let list = () => {
            let arr = [];
            if (this.props.events)
                for (let [index, event] of Object.entries(this.props.events)) {
                    if (!event.exclude) {

                        let key = event.id

                        arr.push(
                            <EventField
                                key={key}
                                enabled={event.enabled}
                                id={key}
                                name={event.name}
                                type={event.type}
                                time={event.time}
                                className="list-item"
                                onRemove={() => { if (this.props.onRemove) this.props.onRemove(key) }}
                                onPropertyChange={(name, value) => { this.eventPropertyChanged(key, name, value) }}
                                onChange={(event) => { this.eventChanged(key, event) }}
                            />
                        );
                    }
                }
            return arr;
        };

        return <div className="window">
            <CollapseHeader text="Eventlist" />
            <div className="collapsable">
                <div className="container">

                    {list()}
                    <Button onClick={this.addEvent}>
                        <Add />
                    </Button>
                </div>
            </div>
        </div>;
    }

    getFrom(time) {
        if (Array.isArray(time) && time.length > 0) {
            return time[0]
        } else return time
    }

    getTo(time) {
        if (Array.isArray(time) && time.length > 1) {
            return time[1]
        } else return ""
    }

    eventPropertyChanged(key, name, value) {
        let events = this.props.events.slice()

        let match = events.findIndex(e => {
            return e.id == key
        })

        if (match != -1) {
            events[match][name] = value
            if (this.props.onChange) this.props.onChange(events)
        }

    }

    eventChanged(key, state) {
        let events = this.props.events.slice()

        let match = events.findIndex(e => {
            return e.id == key
        })

        if (match != -1) {
            events[match] = state
            if (this.props.onChange) {
                console.log(state)
                this.props.onChange(events)
            }
        } else console.error("Id was not found in events!", events, key)
    }


    addEvent() {
        if (this.props.onAdd) this.props.onAdd(this.blankEvent)
    }

    get blankEvent() {
        const id = uuid()
        return {
            name: "",
            type: "",
            enabled: true,
            time: [],
            id
        }
    }
    // addItem() {
    //     let events = this.props.events.slice()
    //     events.push({
    //         start: null,
    //         end: null,
    //         name: ""
    //     })
    //     this.setState({ events })
    //     this.updateEventList(events)
    // }


    // updateEventList(list) {
    //     console.log("Save: ", list)
    //     Cookies.set("event-list", JSON.stringify(list))
    // }

    // loadEventList() {
    //     let data = []
    //     let dataString = Cookies.get("event-list")
    //     if (dataString) {
    //         data = JSON.parse(dataString)
    //     }

    //     return data
    // }
}