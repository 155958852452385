import React, { Component } from "react";
import Cookies from "js-cookie";
import LabelField from "./labelField";
import CollapseHeader from "./collapseheader";

export default class Profile extends Component {
    constructor(props) {
        super(props)
        console.log(props.data)
        this.changed = this.changed.bind(this)
    }

    render() {
        
        let list = () => {
            let arr = [];
            if (this.props.data)
                for (let [key, val] of Object.entries(this.props.data))
                    arr.push(
                        <LabelField
                            key={key}
                            label={key}
                            value={val}
                            onChange={event => {
                                this.changed(event, key);
                            }}
                        />
                    );
            return arr;
        };

        return <div className="window">
            <CollapseHeader text="Profile" />
            <div className="collapsable">
                <div className="container">
                    {list()}
                </div>
            </div>
        </div>;
    }




    load() {
        return {
            name: this.getCookie("name"),
            birthday: this.getCookie("birthday"),
            street: this.getCookie("street"),
            city: this.getCookie("city"),
            postal_code: this.getCookie("postal_code")
        };
    }

    getCookie(name) {
        let val = Cookies.get(name);
        return val ? val : "";
    }

    changed(event, type) {

        if (this.props.onChange) {
            let out = this.props.data;
            out[type] = event.target.value;
            this.props.onChange(out);
        }
    }
}
