import React, { Component } from 'react';
import Dialog from './dialog.js';
import Button from './button.js';

export default class Toolbar extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }

        this.clearDialogReference = React.createRef()
        this.exampleDialogReference = React.createRef()
        this.importDialogReference = React.createRef()

        this.requestImport = this.requestImport.bind(this)
        this.executeImport = this.executeImport.bind(this)
        this.requestExample = this.requestExample.bind(this)
        this.loadExample = this.loadExample.bind(this)
        this.requestClear = this.requestClear.bind(this)
        this.executeClear = this.executeClear.bind(this)

    }

    render() {

        let importButtonClasses = "block-label custom-file-upload flex button"
        importButtonClasses += (!this.props.onImport) ? " disabled" : "";

        return <div><div className="window toolbar">
            <Button onClick={this.loadExample}>Example</Button>
            <Button className="flex" onClick={this.requestClear}>Clear</Button>
            <label className={importButtonClasses}>
                <input type="file" onChange={this.requestImport} />
                <span>Import</span>
            </label>
            <Button className="flex" onClick={this.props.onExport}>
                <span>Export</span>
            </Button>
            <label className={(this.props.printMode) ? "button toggle-button" : "button toggle-button enabled"}> Print Mode
                <input type="checkbox" checked={(this.props.printMode == true)}

                    onChange={this.props.onPrintModeChanged} />
            </label>

        </div>
            <Dialog
                ref={this.clearDialogReference}
                title="Do you really want to clear all entries? This cannot be undone!"
                info="You may download the current changes with the 'Export' button, before clearance."
                onSubmit={this.executeClear}
            />
            <Dialog
                ref={this.importDialogReference}
                title="Do you really want to clear all entries? This cannot be undone!"
                info="You may download the current changes with the 'Export' button, before import."
                onSubmit={this.executeImport}
            />
            <Dialog
                ref={this.exampleDialogReference}
                title="Do you really want to clear all entries? This cannot be undone!"
                info="You may download the current changes with the 'Export' button, before loading the example."
                onSubmit={this.props.loadExample}
            />
        </div>
    }

    requestImport(event) {
        this.importDialogReference.current.show(event.target.files[0])
    }

    executeImport(data) {
        console.log(data)
        if (this.props.onImport) this.props.onImport(data)
    }

    requestExample() {
        this.exampleDialogReference.current.show()
    }

    loadExample() {
        if (this.props.onLoadExample) this.props.onLoadExample()
    }


    requestClear() {
        this.clearDialogReference.current.show()
    }

    executeClear() {
        if (this.props.onClear) this.props.onClear()
    }

}