import React, { Component } from 'react';


export default class LabelField extends Component {


    render(){
        return <div className="label-field">
            <label>{this.props.label}<input onChange={this.props.onChange} type={this.props.type} value={this.props.value} /></label>
        </div>
    }
}