

import Cookies from "js-cookie";


export default class Loader {

    static saveTheme(obj) {
        let themeAsString = JSON.stringify(obj)
        localStorage.setItem("theme", themeAsString)
    }

    static loadPicture(path){
        return localStorage.getItem("picture")

    }

    static savePicture(path){
        localStorage.setItem("picture", path)
    }

    static loadTheme() {
        let theme = {
            color: "#000000"
        }

        try{
           theme = JSON.parse(localStorage.getItem("theme")) 
        }catch(e){
            console.error(`Could not parse stored theme object: ${e}.`)
        }

        return theme
    }

    static loadData() {
        let data = localStorage.getItem("data")
        data = JSON.parse(data)
        console.log("Data was loaded: ", data)
        return  data
    }

    static saveData(obj){
        let dataAsString = JSON.stringify(obj)
        console.log("Data was saved: ", obj)
        localStorage.setItem("data", dataAsString)
    }
}