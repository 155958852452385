import React, { Component } from 'react';
import Button from './button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from "@material-ui/icons/ExpandLess"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Delete from "@material-ui/icons/Delete"
import TimeSpan from './timespan';
import Dialog from "./dialog";

export default class EventField extends Component {


    constructor(props) {
        super(props)

        this.state = {
            id: props.id,
            name: props.name,
            type: props.type,
            time: props.time,
            enabled: props.enabled,
            collapsed: true
        }



        this.dialogReference = React.createRef()
        this.openDialog = this.openDialog.bind(this)

        this.handleTextChange = this.handleTextChange.bind(this)

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleTypeChange = this.handleTypeChange.bind(this)
        this.handleTimeChange = this.handleTimeChange.bind(this)
        this.changeVisibility = this.changeVisibility.bind(this)
    }

    eventChange() {
        this.props.onChange(this.state)
    }

    handleTextChange(prop, e) {
        let obj = {}
        obj[prop] = e.target.value
        this.setState(obj, () => {
            this.eventChange()
        })
    }

    handleNameChange(e) {
        this.handleTextChange("name", e)
    }

    handleTypeChange(e) {
        this.handleTextChange("type", e)

    }
    handleTimeChange(time) {
        this.setState({
            time
        },
            this.eventChange
        )
    }

    changeVisibility(e) {
        this.setState((state) => ({
            enabled: !state.enabled
        }), () => {
            this.props.onPropertyChange("enabled", this.state.enabled)
        })

    }


    render() {
        const defaultClassNames = "event-field flexbox column"
        let className = defaultClassNames
        if (this.props.className) {
            className += " " + this.props.className
        }

        if (this.state.collapsed) {
            className += " collapsed"
        }

        function PickVisibilityIcon(props) {
            if (props.IsVisible)
                return <Visibility />
            else
                return <VisibilityOff />
        }

        function PickExpandIcon(props) {
            if (props.IsCollapsed)
                return <ExpandMore />
            else
                return <ExpandLess />
        }


        return <div className={className} >
            <div className="row">
                <Button name="enabled" onClick={this.changeVisibility}>
                    <PickVisibilityIcon
                        IsVisible={this.state.enabled}
                    />
                </Button>
                <input className="flex" name="name" value={this.state.name} onChange={this.handleNameChange} placeholder="Name" />
                <Button onClick={() => { this.setState({ collapsed: !this.state.collapsed }) }}>
                    <PickExpandIcon
                        IsCollapsed={this.state.collapsed}
                    />
                </Button>
            </div>
            <div className="collapse-container">

                <div className="row">
                    <label className="title" htmlFor={"type-" + this.props.id}>Type</label>
                    <input className="flex" name="type" id={"type-" + this.props.id} value={this.state.type} onChange={this.handleTypeChange} placeholder="Type" />
                </div>

                <TimeSpan time={this.state.time} onChange={this.handleTimeChange} />
                <div className="row">
                    <span className="fake-input disabled flex">{this.props.id}</span>
                    <Button onClick={this.openDialog} className="danger">

                        <Delete />
                    </Button>

                    <Dialog
                        ref={this.dialogReference}
                        showDialog={this.state.showDialog}

                        title="Do you really want to to remove this element?"
                        info="You could just disable the visibilty by clicking the eye icon!"
                        onSubmit={this.props.onRemove}

                    />
                </div>

            </div>
        </div >
    }

    openDialog() {
        this.dialogReference.current.show()
    }


}