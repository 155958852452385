import React, { Component } from 'react';
import CollapseHeader from './collapseheader';
import SkillField from './skillfield';
import Button from './button';
import Add from '@material-ui/icons/Add';
import { v4 as uuid } from "uuid";


export default class SkillList extends Component {

    constructor(props) {
        super(props)


        this.changed = this.changed.bind(this)
        this.remove = this.remove.bind(this)
    }


    changed(skill) {
        let skills = this.props.skills.slice()
        let match = skills.findIndex(search => search.id == skill.id)
        if (match != -1) {
            skills[match] = skill
            if (this.props.onChange) {
                this.props.onChange(skills)
            }
        } else {
            console.error("Could not update skill. Id was not found.", skill.id, skill)
        }

    }

    skillPropertyChanged(key, name, value) {
        let skill = this.props.skills.slice()

        let match = skill.findIndex(e => {
            return e.id == key
        })

        if (match != -1) {
            skill[match][name] = value
            if (this.props.onChange) this.props.onChange(skill)
        }

    }

    remove(key) {
        console.log("REMOVE", arguments)

        if (this.props.onRemove) {
            console.log(key)
            this.props.onRemove(key)
        }
    }

    render() {
        let types = []
        let list = function () {
            return <p>List is empty!</p>
        }


        if (this.props.skills && Array.isArray(this.props.skills)) {
            this.props.skills.forEach(element => {
                let type = (element && element.type) ? element.type : "None"

                if (types.indexOf(element.type) == -1) types.push(type)

            })


            list = (types) => {
                let listItems = []
                if (this.props.skills) {
                    this.props.skills.forEach((skill) => {

                        let key = skill.id

                        listItems.push(
                            <SkillField
                                key={key}
                                id={key}
                                enabled={skill.enabled}
                                name={skill.name}
                                type={skill.type}
                                value={skill.value}
                                onPropertyChange={(name, value) => { this.skillPropertyChanged(key, name, value) }}
                                onChange={(skill) => { this.changed(skill) }}
                                onRemove={() => { this.remove(key) }}
                                className="list-item"
                            />
                        )

                    })
                }
                return listItems
            }
        }

        return <div className="window">
            <CollapseHeader text="Skills" />
            <div className="collapsable">
                <div className="container">
                    {list(types)}
                </div>
                <Button className="button" onClick={() => { this.props.onAdd(this.blankSkill) }}><Add /></Button>
            </div>
        </div>
    }

    get blankSkill() {
        return {
            enabled: true,
            name: "",
            value: 0,
            type: "",
            id: uuid()
        }
    }

}