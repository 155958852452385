import React, { Component } from 'react';


export default class TimeSpan extends Component {
    constructor(props) {
        super(props)

        let from = (props.time && props.time.length && props.time.length > 0) ? props.time[0] : 1900
        let to = (props.time && props.time.length && props.time.length > 0) ? props.time[1] : 1920

        this.state = {
            from,
            to
        }

        this.changed = this.changed.bind(this)
        this.toChanged = this.toChanged.bind(this)
        this.fromChanged = this.fromChanged.bind(this)
    }

    changed() {
        if (this.props.onChange) {
            let time = [
                this.state.from,
                this.state.to
            ]
            this.props.onChange(time)
        }
    }

    fromChanged(e) {
        this.setState({
            from: e.target.value
        }, this.changed)
    }

    toChanged(e) {
        this.setState({
            to: e.target.value
        }, this.changed)
    }

    render() {

        return <div className="row">
            <label className="title">Time</label>
            <input type="text" name="time-from" className="flex" onChange={this.fromChanged} value={this.state.from} placeholder="Since" />
            <input type="text" name="time-to" className="flex" onChange={this.toChanged} value={this.state.to} placeholder="Until" />
        </div>
    }
}