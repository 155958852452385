export default class Time {
    static stringify(time) {
        console.log(time)
        if (Array.isArray(time)) {
            if (time.length > 1)
                return (!time[1] || time[1] == "") ? time[0] : time[0] + " - " + time[1]
            else
                return time[0]
        } else {
            return time
        }
    }
}