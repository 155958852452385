import React, { Component } from 'react';
import "./css/preview.css"

export default class Preview extends Component {

    constructor(props) {
        super(props)


        this.renderProfile = this.renderProfile.bind(this)
        this.zoom = this.zoom.bind(this)

        this.translation = this.defaultTranslation
        this.zoomFactor = this.defaultZoom

        this._page = React.createRef()

        this.resetView = this.resetView.bind(this)
        this.updateTransform = this.updateTransform.bind(this)
    }

    get defaultZoom(){
        return 0.5
    }

    get defaultTranslation() {
        return { x: 0, y: 0 }
    }

    render() {
        return <div className="preview">
            <div className="page" ref={this._page}>
                {this.props.children}
            </div>
        </div>
    }

    componentDidMount() {
        this.zoom(0)
    }

    renderProfile() {
        let out = []
        for (let val of Object.values(this.props.profile)) {
            out.push(
                <div>{val}</div>
            )
        }

        return out
    }

    zoom(deltaY) {
        if (this._page && this._page.current) {
            this.zoomFactor = (this.zoomFactor + deltaY * 0.001 < 0) ? 0 : this.zoomFactor + deltaY * 0.001
            this.updateTransform()
        }
    }

    pan(deltaPoint) {
        deltaPoint = Object.assign({
            x: 0, y: 0
        }, deltaPoint)

        if (deltaPoint.x != 0 || deltaPoint.y != 0) {
            this.translation.x += deltaPoint.x
            this.translation.y += deltaPoint.y
            this.updateTransform()
        }
    }

    resetView() {
        this.zoomFactor = this.defaultZoom
        this.translation = Object.assign({}, this.defaultTranslation)
        this.updateTransform()
    }

    updateTransform() {
        Object.assign(this._page.current.style, {
            transform: "translate(" + this.translation.x + "px, " + this.translation.y + "px) scale(" + this.zoomFactor + ")"
        })
    }
}