import React from "react";

export default class ProgressRing extends React.Component { 
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI + Math.PI;
  }

  render() {
    const { text, radius, stroke, progress, color } = this.props;

    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

    return ( 
      <div className="progress-ring">
        <svg
          className="progress-circle"
          height={radius * 2}
          width={radius * 2}
        > 
          <text x={radius} y={radius} textAnchor="middle" alignmentBaseline="middle" dominant-baseline="middle">{progress}</text>
          <circle
            stroke={color}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{ strokeDashoffset }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <p>{text}</p>
      </div>
    );
  }
}