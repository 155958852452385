
import React, { Component } from "react";

/**
 * Header of a window, that closes specified content
 * when clicked.
 * 
 * @param {string} text - The text of the component.
 */
export default class CollapseHeader extends Component {
    constructor(props) {
        super(props)
        this.collapse.bind(this)
    }

    render() {
        return <header className="collapsor interactive" onClick={this.collapse}>
            <h2>{this.props.text}</h2>
        </header>
    }

    collapse(event) {
        let sender = event.currentTarget
        let window = sender
        while (!window.classList.contains("window") && window.parentNode != null) {
            window = sender.parentNode
        }

        if (window.classList.contains("window")) {
            let collapsed = window.classList.contains("collapsed")

            if (collapsed)
                window.classList.remove("collapsed")
            else
                window.classList.add("collapsed")
        }
    }
}
