import React, { Component } from 'react';

export default class Dialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data:null,
            active: props.active || false
        }

        this.hide = this.hide.bind(this)
        this.show = this.show.bind(this)
        this.submit = this.submit.bind(this)
    }

    render() {

        let dialogClassName = "dialog"
        if (!this.state.active) dialogClassName += " hidden"

        let submitButtonClass = "button"
        if(!this.props.onSubmit) submitButtonClass += " disabled"

        return <div className={dialogClassName}>
            <b>{this.props.title}</b>
            <p>{this.props.info}</p>
            <div className="controls">
                <div className={submitButtonClass} onClick={this.submit}><span>Yes</span></div>
                <div className="button" onClick={this.hide}><span>No</span></div>
            </div>
        </div>
    }

    show(data=null) {
        this.setState({ 
            active: true ,
            data
        })
    }

    hide() {
        this.setState({ active: false })
    }

    submit(){
        if(this.props.onSubmit) this.props.onSubmit(this.state.data)
        this.hide()
    }

}