import React, { Component } from "react";
import ReactDOM from "react-dom";
import Toolbar from "./toolbar";
import EventList from "./eventslist";
import Preview from "./preview";
import Data from "../misc/data";

import "../css/styling.css";
import "../css/layout.css";
import Profile from "./profile";
import SkillList from "./skilllist";
import Picture from "./picture";


import MyLocation from "@material-ui/icons/MyLocation"

import SideCardTemplate, { SideCardControls } from "../templates/sidecard/sidecard";
import Loader from "../utils/loader";
import { v4 as uuid } from "uuid";
import axios from "axios";


export default class App extends Component {
    constructor(props) {
        super(props);
        this.i = 0;
        this.lastPanPosition = null



        let PrintMode = {
            Color: 0,
            BlackAndWhite: 1,
            fromInt: function (num) {
                if (num == 1) return "BlackAndWhite"
                else return "Color"
            }
        }

        let themeData = Loader.loadTheme()
        let loadedProfileData = Loader.loadData()
        let image = Loader.loadPicture()

        let events = loadedProfileData && loadedProfileData.events ? loadedProfileData.events : []


        for (let index in events) {
            const id = uuid()
            events[index].id = id
        }

        let skills = loadedProfileData && loadedProfileData.skills ? loadedProfileData.skills : []


        if (!Array.isArray(skills)) {
            skills = []
            console.error("Loaded data was corrupted: used empty array for skills.", skills)
        }
        for (let index in skills) {
            const id = uuid()
            skills[index].id = id
        }

        this.state = {
            printMode: PrintMode.Color,
            primaryColor: themeData ? themeData.color ? themeData.color : "#000000" : "#0000ff",
            picturePivot: { x: 0.5, y: 0.5 },
            image,
            events,
            skills,
            profile: loadedProfileData && loadedProfileData.profile ? this.mapProfile(loadedProfileData.profile) : this.emptyProfile
        };


        // this.setupLeaveDialog()

        this._preview = React.createRef()

        this.zoomPreview = this.zoomPreview.bind(this)
        this.changed = this.changed.bind(this)

        this.pictureChanged = this.pictureChanged.bind(this)
        this.picturePivotChanged = this.picturePivotChanged.bind(this)


        this.profileChanged = this.profileChanged.bind(this);

        this.loadExample = this.loadExample.bind(this)

        this.eventRemoved = this.eventRemoved.bind(this)
        this.skillRemoved = this.skillRemoved.bind(this)
        this.removeListItem = this.removeListItem.bind(this)

        this.eventListChanged = this.eventListChanged.bind(this)
        this.skillListChanged = this.skillListChanged.bind(this)

        this.import = this.import.bind(this);
        this.export = this.export.bind(this)
        this.clear = this.clear.bind(this)
    }


    render() {

        return < div className="app" >
            <main>
                <aside>

                    <Toolbar
                        printMode={this.state.printMode}
                        onPrintModeChanged={(event) => {
                            this.setState({
                                printMode: (event.currentTarget.checked) ? 1 : 0
                            })
                        }}

                        onLoadExample={this.loadExample}
                        onExport={this.export}
                        onImport={this.import}
                        onClear={this.clear}
                    />

                    <SideCardControls
                        color={this.state.primaryColor}
                        onColorChanged={(event) => {
                            this.setState({ primaryColor: event.target.value }, () => {
                                Loader.saveTheme({
                                    color: this.state.primaryColor
                                })
                            })

                        }} />

                    <Picture
                        image={this.state.image}
                        pivot={this.state.picturePivot}
                        onChange={this.pictureChanged}
                        onPivotChanged={this.picturePivotChanged}
                    />

                    <Profile
                        data={this.state.profile}
                        onChange={this.profileChanged}
                    />

                    <EventList
                        events={this.state.events}
                        onChange={this.eventListChanged}
                        onAdd={(obj) => { this.add("events", obj) }}
                        onRemove={this.eventRemoved}
                    />
                    <SkillList
                        skills={this.state.skills}
                        onChange={this.skillListChanged}
                        onRemove={this.skillRemoved}
                        onAdd={(obj) => { this.add("skills", obj) }}
                    />

                </aside>
                <div className="flexbox flex column preview-canvas" onWheel={this.zoomPreview}

                    onMouseDown={
                        (event) => {
                            if (event.button === 1) {
                                this.lastPanPosition = { x: event.clientX, y: event.clientY }
                            }
                        }
                    }
                    onMouseMove={(event) => {

                        if (this.lastPanPosition) {
                            let current = { x: event.clientX, y: event.clientY }
                            let delta = {
                                x: current.x - this.lastPanPosition.x,
                                y: current.y - this.lastPanPosition.y
                            }
                            this.lastPanPosition = current
                            this._preview.current.pan(delta)
                        }

                    }}

                    onMouseUp={(event) => {
                        if (event.button === 1) {
                            this.lastPanPosition = null
                        }
                    }}

                    onMouseOut={(event) => {
                        this.lastPanPosition = null
                    }}
                >
                    <div className="toolbar window" >
                        <div className="button" onClick={() => {
                            this._preview.current.resetView()
                        }}>
                            <MyLocation />
                        </div>
                    </div>

                    <Preview ref={this._preview}>
                        <SideCardTemplate
                            printMode={this.state.printMode}
                            picture={this.state.image}
                            picturePivot={this.state.picturePivot}
                            profile={this.state.profile}
                            events={this.state.events}
                            skills={this.state.skills}
                            color={this.state.primaryColor}
                        />
                    </Preview>
                </div>
            </main>
        </div >
    }

    loadExample() {
        const exampleDataPath = "/data/example/template.json"
        axios.get(exampleDataPath).then((result) => {
            this.load(result.data)
        }).catch(console.error)

        const exampleImagePath = "/data/example/einstein.jpg"
        Loader.savePicture(exampleImagePath)


        const primaryColor = "#29c035"

        const pivot = {
            x: 0.5,
            y: 0.2
        }


        this.setState({
            image: exampleImagePath,
            picturePivot: pivot,
            primaryColor
        }, this.changed())
    }

    setupLeaveDialog() {
        window.addEventListener("beforeunload", (event) => {
            // event.preventDefault()
            event.returnValue = "Are you sure you want to leave? Your changes will be lost. Export to save them locally!"
        })
    }

    add(prop, obj) {
        if (this.state[prop]) {
            this.setState((prevState) => {
                prevState[prop] = [...prevState[prop], obj]
                return prevState
            })
        }
    }

    pictureChanged(src) {
        this.setState({
            image: src
        }, () => {
            Loader.savePicture(src)
        })
    }

    picturePivotChanged(pivot) {
        this.setState({
            picturePivot: pivot
        })
    }

    zoomPreview(event) {
        if (this._preview && this._preview.current && this._preview.current.zoom)
            this._preview.current.zoom(-event.deltaY)
    }


    mapProfile(profileData) {
        let output = this.emptyProfile
        let copy = Object.assign({}, profileData)

        function assignIfExists(input, reference) {
            for (let key of Object.keys(input)) {
                if (reference.hasOwnProperty(key)) {
                    reference[key] = input[key]
                }
            }
        }
        assignIfExists(copy, output)

        return output
    }

    get emptyProfile() {
        return {
            name: "",
            street: "",
            city: "",
            postal_code: "",
            country: "",
            birthday: "",
            birthplace: "",
            citizenship: "",
            marital_status: "",
            phone: "",
            mail: "",
            website: ""
        }
    }


    clear() {

        this.setState({
            image: null,
            profile: this.emptyProfile,
            skills: [],
            events: []
        }, this.changed)

        localStorage.clear()

    }

    skillListChanged(arr) {
        this.setState({ "skills": arr }, this.changed)
    }

    eventListChanged(arr) {
        this.setState({ "events": arr }, this.changed)
    }


    skillRemoved(id) {
        this.removeListItem("skills", id)

    }

    eventRemoved(id) {
        this.removeListItem("events", id)
    }

    removeListItem(name, id) {
        let arr = this.state[name].filter(el => el.id != id)
        let state = {}
        state[name] = arr
        this.setState(state, this.changed)
    }

    profileChanged(obj) {
        let profile = this.mapProfile(obj)
        this.setState({ "profile": profile }, this.changed)
    }

    changed() {


        let personalData = {}
        personalData["profile"] = this.state.profile
        personalData["events"] = this.state.events
        personalData["skills"] = this.state.skills

        
        console.log(this.state, personalData)

        Loader.saveData(personalData)
    }

    export() {
        let obj = {
            profile: this.state.profile,
            skills: this.state.skills,
            events: this.state.events
        }


        let dataString = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj))
        let shadowLink = document.createElement("a")
        shadowLink.setAttribute("href", dataString)
        shadowLink.setAttribute("download", "export.json")

        shadowLink.style.display = "none"
        //Required for firefox.
        document.body.appendChild(shadowLink)

        shadowLink.click()
        shadowLink.remove()
    }

    load(data) {
        let obj = {}
        if (data.profile) obj["profile"] = this.mapProfile(data.profile)
        if (data.skills) {
            obj.skills = data.skills

            // Set enabled to true if there is none set.
            obj.skills.forEach(skill => {
                if (!skill.hasOwnProperty("enabled")) {
                    skill.enabled = true
                }

                skill.id = uuid()
            })
        }
        if (data.events) {
            obj.events = data.events
            obj.events.forEach(event => {
                if (!event.hasOwnProperty("enabled")) {
                    event.enabled = true
                }

                event.id = uuid()
            })
        }
        this.setState(obj, this.changed)
    }

    import(file) {
        Data.import(file)
            .then(importedData => {
                this.load(importedData)
            })
            .catch(err => {
                console.error(err);
            });
    }
}
