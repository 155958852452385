import React, { Component } from 'react';
import CollapseHeader from '../components/collapseheader';

export default class Template {

    constructor(preview, controls){
        this.controls = controls
        this.preview = preview
    }



}

export class TemplatePreview extends Component {
    get CSS() {
        console.error("Templates mus specify a css file.")
        return null
    }

    renderControls() {
        return <div className="window">
            <CollapseHeader text={"Template (" + this.name + ")"} />
            <div className="container">
                {this.controls}
            </div>
        </div>
    }

    get controls() {
        return <b>Overload in subclass!</b>
    }

    get name() {
        return "NAME NOT IMPLEMENTED"
    }
}