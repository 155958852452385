
import React, { Component } from 'react';
import CollapseHeader from './collapseheader';
import AddAPhoto from "@material-ui/icons/AddAPhoto";

export default class Picture extends Component {

    constructor(props) {
        super(props)

        this._pivotWrapperRef = React.createRef()
        this._imageRef = React.createRef()
        this._pivotRef = React.createRef()

        this.previewFile = this.previewFile.bind(this)


        this.startMovingPivot = this.startMovingPivot.bind(this)
        this.endMovingPivot = this.endMovingPivot.bind(this)
        this.movePivot = this.movePivot.bind(this)

    }

    componentDidMount() {
        this._pivotRef.current.addEventListener("mousedown", this.startMovingPivot)
    }

    startMovingPivot() {
        this._pivotWrapperRef.current.addEventListener("mousemove", this.movePivot)
        this._pivotWrapperRef.current.addEventListener("mouseleave", this.endMovingPivot)
        this._pivotWrapperRef.current.addEventListener("mouseup", this.endMovingPivot)
    }
    endMovingPivot() {
        this._pivotWrapperRef.current.removeEventListener("mousemove", this.movePivot)
        this._pivotWrapperRef.current.removeEventListener("mouseleave", this.endMovingPivot)
        this._pivotWrapperRef.current.removeEventListener("mouseup", this.endMovingPivot)
    }
    movePivot(event) {

        if (this.props.onPivotChanged) {
            let target = event.target
            if (target == this._pivotWrapperRef.current) {
                const width = target.offsetWidth
                const height = target.offsetHeight

                let percent = {
                    x: event.offsetX / width,
                    y: event.offsetY / height
                }

                this.props.onPivotChanged(percent)
            }
        }
    }



    render() {

        let pivotStyle = {
            left: (this.props.pivot.x * 100) + "%",
            top: (this.props.pivot.y * 100) + "%"
        }

        return <div className="window">
            <CollapseHeader text="Picture" />
            <div className="collapsable ">
                <div className="picture-wrapper" >
                    <img ref={this._imageRef} alt="Person" className="picture-field" src={this.props.image}></img>

                    <div className="pivot-wrapper" ref={this._pivotWrapperRef} >
                        <div className="pivot interactive" ref={this._pivotRef} style={pivotStyle} />
                    </div>
                </div>
                <label className="button">
                    <AddAPhoto />
                    <input type="file" style={{ display: "none" }} onChange={this.previewFile} />
                </label>
            </div>
        </div>
    }



    previewFile(event) {
        var file = event.target.files[0]; //sames as here
        var reader = new FileReader();

        reader.onloadend = () => {
            if (this.props.onChange) {
                this.props.onChange(reader.result)
            }
        }

        if (file) {
            reader.readAsDataURL(file); //reads the data as a URL
        }
    }
}