import React, { Component } from "react";
import { TemplatePreview } from "../../misc/template.js";
import Time from "../utils/time.js";
import ProgressRing from "../utils/progressring.js"
import CollapseHeader from "../../components/collapseheader.js";


import "./scss/sidecard.scss";
import "./fonts/fonts.css";

export class SideCardControls extends Component {

    render() {
        return (
            <div className="window">
                <CollapseHeader text="Template" />
                <div className="container row flex flexbox">
                    <label htmlFor="primary-color-input" className="flex">Primary Color</label>
                    <input id="primary-color-input" type="color" value={this.props.color} onChange={this.props.onColorChanged} />
                </div>
            </div>
        )
    }
}

export default class SideCardTemplate extends TemplatePreview {


    get resources() {
        return (
            <link href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,900|Vollkorn:400,400i,600,700,900" rel="stylesheet"></link>
        )
    }

    get name() {
        return "Side Pride"
    }

    get primaryForegroundStyle() {
        return { color: this.props.color }
    }

    get primaryBorderStyle(){
        return {borderColor: this.props.color}
    }

    get primaryBackgroundStyle() {
        return { backgroundColor: this.props.color }
    }


    get primaryBorderStyle() {
        return { borderBottom: "3px solid " + this.props.color }
    }

    render() {

        let picturePivotStyle = {
            objectPosition: this.props.picturePivot.x * 100 + "% " + this.props.picturePivot.y * 100 + "%"
        }

        return (
            <div className={this.props.printMode == 0 ? "template sidecard-template print-color-mode" : "template sidecard-template"}>
                <aside>
                    <div className="actual-border">
                        <img src={this.props.picture} alt="Portrait" style={Object.assign({}, picturePivotStyle, this.primaryBorderStyle)} />
                        <div className="content">
                            <h2>{this.props.profile.name}</h2>
                            <span>
                                Geburtstag: {this.props.profile.birthday}
                            </span>
                            <br />
                            <span>
                                Geburtsort: {this.props.profile.birthplace}
                            </span>
                            <h3>Kontakt</h3>
                            <span>{this.props.profile.street}</span>
                            <br></br>
                            <span>{this.props.profile.postal_code} {this.props.profile.city}</span>
                            <br></br>
                            <span>Tel: {this.props.profile.phone}</span>
                            <br></br>
                            <span>E-Mail: {this.props.profile.mail}</span>
                            <br></br>
                            {this.props.profile.website &&
                                <div>
                                    <span>Website: {this.props.profile.website}</span>
                                    <br></br>
                                </div>
                            }

                            {this.listSkills()}

                        </div>
                    </div>
                </aside>
                <div className="main content">
                    <header>
                        <h1 style={this.primaryForegroundStyle} >Lebenslauf</h1>
                        <h1  >{this.props.profile.name}</h1>
                    </header>

                    {this.listEvents()}
                </div>

            </div>
        )
    }

    get color() {
        return (this.props.color) ? this.props.color : "black"
    }

    listEvents() {
        let out = []

        let groups = {}
        if (this.props.events) {

            this.props.events.forEach(event => {
                if (event.enabled) {
                    if (!groups.hasOwnProperty(event.type)) {
                        groups[event.type] = []
                    }
                    groups[event.type].push(event)
                }
            })


            for (let key in groups) {


                const createGroup = (group) => {
                    let groupDom = []
                    groupDom.push(
                        <h3 key={key + "-heading"} style={this.primaryForegroundStyle}>{key}</h3>
                    );

                    let tableContent = []

                    group.forEach((element, idx) => {

                        if (element.time) {
                            tableContent.push(
                                <div className="row" key={key + "-" + idx}>
                                    <div className="time">{Time.stringify(element.time)}</div>
                                    <div>
                                        <p className="name">{element.name}</p>
                                        {element.description && <p className="description">{element.description}</p>}
                                    </div>

                                </div>
                            )
                        } else {



                            tableContent.push(
                                <div className="row" key={key + "-" + idx}>
                                    <div>
                                        <p className="name">{element.name}</p>
                                        {element.description && <p className="description">{element.description}</p>}
                                    </div>
                                </div >
                            )
                        }


                    });

                    groupDom.push(<div key={key}>
                        {tableContent}
                    </div>)

                    return groupDom
                }



                let group = groups[key]

                let eventContainer = <div className="event-group group" key={key}>{createGroup(group)}</div>
                out.push(eventContainer)
            }


        }

        return out
    }



    listSkills() {

        let groups = {}

        if (this.props.skills != null && Array.isArray(this.props.skills)) {
            this.props.skills.forEach(skill => {

                if (skill.enabled) {
                    const addType = (skill, type) => {
                        if (!groups.hasOwnProperty(type))
                            groups[type] = []

                        groups[type].push(skill)
                    }

                    if (skill !== null)
                        if (skill.type) {
                            addType(skill, skill.type)
                        } else {
                            addType(skill, ".")
                        }
                }
            })
        }

        const createSkillGroup = (key, group) => {
            let collection = []
            collection.push(<h3 key={key}>{key}</h3>)

            let skills = []
            group.forEach((skill, idx) => {
                if (skill.enabled) {
                    skills.push(<ProgressRing
                        key={key + "-" + idx}
                        text={skill.name}
                        color={this.color}
                        stroke="4"
                        radius="26"
                        progress={Math.floor(skill.value * 100)}
                    />)
                }
            })

            collection.push(<div className="circle-list">{skills}</div>)
            return collection
        }

        let out = []

        for (let key of Object.keys(groups)) {

            groups[key].sort((a, b) => {
                if (a.name < b.name) return -1
                else if (a.name > b.name) return 1
                else return 0
            })

            out.push(<div className="skill-group-wrapper"><div className="skill-group group">{createSkillGroup(key, groups[key])}</div></div>)
        }
        return out
    }
}
