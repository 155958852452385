
 export default class Data {
    static import(file){
        return new Promise((resolve,reject)=>{
            let reader = new FileReader()
    
            reader.readAsText(file, "UTF-8")
            reader.onload = (event)=>{
                try{
                    let data = JSON.parse(event.target.result)
                    if(this.validate(data)){
                        resolve(data)
                    }else{
                        reject("Loaded data was not valid.")
                    }
                }catch(err){
                    reject("Loaded file was not valid JSON: ", err)
                }
            }

            reader.onerror = (event) =>{
                reader.abort()
                reject("File could not be loaded due to error.")
            }

            reader.onabort = (event) =>{
                reject("File loading was aborted.")
            }
        })
    }

    static validate(obj){
        return true
    }

    static export(){

    }


}