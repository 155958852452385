import React, { Component } from 'react';

export default class Button extends Component {

    get enabled(){
        return !!this.props.onClick
    }

    render(){

        let buttonClassName = "button interactive" 
        if(this.props.className) buttonClassName += " " + this.props.className
        if(!this.enabled) buttonClassName += " disabled"

        return <div className={buttonClassName} onClick={this.props.onClick}>
            {this.props.children}
        </div>
    }
}